




















































import { IAdmin } from '@/interfaces';
import Vue from 'vue'
import RegisterEye from '@/assets/icons/register-eye.svg'
import RegisterEyeOff from '@/assets/icons/register-eyeoff.svg'
// Admin@Sbee20
export default Vue.extend({
  components:{
    RegisterEye,
    RegisterEyeOff,

  },

  data(): any{
    return {
      loading: false,
      user:{},
      admin:{},
      errors:{},
      showConfirmation: false,
      confirmErrors:[],
      confirmType: 'password',
      confirmIcon: 'RegisterEyeOff',
      passwordType: 'password',
      passwordIcon: 'RegisterEyeOff',

      role: 'Caissier'

    }
  },

  methods: {
    onSubmit(e: any){
      //
      this.loading = true;
      const admin: IAdmin = {
        ...this.admin,
        user: {
          password: this.user.password,
        }
      }
      const reference = this.$route.params['reference']


      if (!reference) {
        return
      }

      // console.log('adminData', admin);
      // setTimeout(() => {
      //   this.loading = false
      //   console.log('adminData', admin);

      // }, 2500);
      this.$store.dispatch('admins/createAdminByInvitation', {reference, adminData: admin})
      .then((response: any)  => {
        this.$router.push({path: '/'})
      })
      .catch((error: any) => {
        console.log('error: ', error);

      })
      .finally(() => { this.loading = false})
    },

    onTelInput(input: string, {countryCallingCode,nationalNumber}: any){
      this.admin.telephone = nationalNumber
      this.admin.countryCallingCode = countryCallingCode
      console.log('countryCallingCode', nationalNumber);

    },

    switchPasswordType(){
      console.log('switch -->');

      this.passwordType
      if (this.passwordType  === 'password') {
        this.passwordType = 'text'
      }else {
        this.passwordType = 'password'
      }
      console.log('passwordType ', this.passwordType);

      this.passwordIcon = this.passwordType === 'password'? 'RegisterEyeOff': 'RegisterEye'
    },

    switchConfirmType(){
      console.log('switch -->');

      this.confirmType
      if (this.confirmType  === 'password') {
        this.confirmType = 'text'
      }else {
        this.confirmType = 'password'
      }
      console.log('confirmType ', this.confirmType);

      this.confirmIcon = this.confirmType === 'password'? 'RegisterEyeOff': 'RegisterEye'
    },

    shouldShowConfirmation( passed: boolean){
      console.log('valid ', passed);

      this.showConfirmation = passed
    }
  },

  computed: {
    validPassword(): boolean{
      return this.user.password && this.user.password.length > 8;
    }
  },

  mounted(){
    const reference = this.$route.params['reference']
    console.log('reference ', reference);
    const role = this.$route.query['role']
    if (role) {
      this.role = role
    }

  }
})
