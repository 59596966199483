var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login bg-white p-6 lg:px-14"},[_c('div',{staticClass:"title text-center my-6"},[_c('div',{staticClass:"text-2xl text-primary-300 text-center"},[_vm._v("Créer Mon Compte")]),_c('div',{staticClass:"text-base mx-8"},[_vm._v("Rejoignez l’entreprise Open SI SAS sur KKiaPay en tant que : "),_c('span',{staticClass:"text-secondary-200"},[_vm._v(_vm._s(_vm.role)+" ")]),_vm._v(" !")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"flex mb-4 w-full"},[_c('ValidationProvider',{staticClass:"w-full mr-4",attrs:{"name":"prénoms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"name":"fisrtname","errors":errors,"label":"Prénoms"},model:{value:(_vm.admin.firstName),callback:function ($$v) {_vm.$set(_vm.admin, "firstName", $$v)},expression:"admin.firstName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"name":"lastname","errors":errors,"label":"Noms"},model:{value:(_vm.admin.lastName),callback:function ($$v) {_vm.$set(_vm.admin, "lastName", $$v)},expression:"admin.lastName"}})]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"telephone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"inputOptions":{id:'phone'}},on:{"input":_vm.onTelInput},model:{value:(_vm.admin.telephone),callback:function ($$v) {_vm.$set(_vm.admin, "telephone", $$v)},expression:"admin.telephone"}}),_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"text-left text-sm text-secondary-100 my-1"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"mode":"aggressive","name":"mot de passe","rules":"required|min:8|min_uppercase|min_number","vid":"user.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-input',{attrs:{"type":_vm.passwordType,"name":"password","autocomplete":"new-password","inputClass":"focus-within:outline-none px-2 w-full bg-white border-none","label":"Mot de passe"},on:{"keyup":function($event){return _vm.shouldShowConfirmation(valid)}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}},[_c('div',{staticClass:"h-full cursor-pointer flex items-center",attrs:{"slot":"append"},on:{"click":function($event){return _vm.switchPasswordType()}},slot:"append"},[_c(_vm.passwordIcon,{tag:"component",staticClass:"mr-2"})],1)]),_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"text-left text-sm text-secondary-100 my-1"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmation),expression:"showConfirmation"}],staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|confirmed:user.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"type":_vm.confirmType,"errors":errors,"name":"confirm_password","autocomplete":"new-password","inputClass":"focus-within:outline-none px-2 w-full bg-white border-none","label":"Confirmer votre mot de passe"},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}},[_c('div',{staticClass:"h-full cursor-pointer flex items-center",attrs:{"slot":"append"},on:{"click":function($event){return _vm.switchConfirmType()}},slot:"append"},[_c(_vm.confirmIcon,{tag:"component",staticClass:"mr-2"})],1)])]}}],null,true)})],1),_c('div',{staticClass:"flex my-6"},[_c('v-button',{staticClass:"px-8",attrs:{"text":"Valider","type":"submit","full":"","loading":_vm.loading,"color":"secondary"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }